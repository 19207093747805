.logo-container {
    z-index: 0;
    width: 500px;
    height: 709px;
    opacity:0;
    position: absolute;
    top: 100;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
   

    svg {
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(0deg) !important;

    }

    .solid-logo {
        position: absolute;
        top: 246px;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
        transform: rotateZ(0deg) !important;
        z-index: 1;

    }
}

.svg-container {
    stroke:white;
    stroke-width: 10;
}

@media screen and (max-width: 720px) {

    .logo-container {
        position: absolute;
        width: 250px;
        height: auto;
        top: 0px;
        right: 80px;
        box-sizing: border-box;
        margin: auto;
        left: auto;
      }
    
      .logo-container svg {
        position: absolute;
        top: 122px;
        right: auto;
        bottom: auto;
        left: 0px;
        margin: auto;
        box-sizing: border-box;
      }
    
    }
    @media screen and (max-height: 400px){
        .logo-container{display: none;}

    }
    @media screen and (max-width: 350px){
        .logo-container{display: none;}

    }


