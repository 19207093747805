::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}
body {
    overscroll-behavior-y: none;
    background-color: #202123;
}

h1 {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    text-align: center;
  }

  .cards {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-left: 70px;
  }
 

 .page {
  padding-left: 40px;
  padding-bottom: 20px;
 }
    