.writing{
    background-color: #202123;
    background-attachment: fixed;
    background-size: cover;
    padding-top: 100px;
    
  }

  .card{
    margin-right:40px!important;
    margin-bottom:60px!important;
    transition: transform 0.3s; 
    }

    .card:hover {
      transform: scale(1.15); /* Apply the hover effect to cards with the class hover-card */
      transition: transform 0.3s; /* Add a smooth transition effect */
  }
