@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}

body{
    margin-bottom: 0;
    bottom: 0;
}
.teach{
    color: white; /* Set the text color */
    text-shadow: 2px 2px 4px gray; 
}
.title {
    padding-top: 50px;
}



.app {
    background-color: #343541;
    display: flex;
    margin-bottom: 0;
   overflow-y: scroll;
}

.side-bar {
    background-color: #202123;
    height: 100vh;
    width: 244px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.history {
    padding: 10px;
    margin: 10px;
    height: 100%;

}

.history li {
    list-style-type: none;
    padding: 15px 0;
    cursor: pointer;
}

.nav {
    border-top: solid 0.5px rgba(255, 255, 255, 0.5);
    padding: 10px;
    margin: 10px;
}

.main {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.info {
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    padding: 10px;
}

.bottom-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input-container {
    position: relative;
    width: 100%;
    max-width: 650px;
}

input {
    width: 100%;
    width: 400px;
    border: none;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 12px 15px;
    border-radius: 5px;
    box-shadow: rgba(0,0,0, 0.05) 0 54px 55px,
     rgba(0,0,0, 0.05) 0 -12px 30px,
     rgba(0,0,0, 0.05) 0 -4px 6px,
     rgba(0,0,0, 0.05) 0 12px 3px,
     rgba(0,0,0, 0.05) 0 -3px 5px;
}


input:focus {
    outline: none;
}

#submit {
    position: absolute;
    bottom: 15px;
    right: 20px;
    cursor: pointer;
}
.feed{
    
    width: 100%;
    padding: 0;
}
.feed li {
    display: flex;
    background-color: #444654;
    width: 100%;
    padding: 20px;
    margin: 20px 0;
}

.feed p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    text-align: left;

}
.feed p.role {
    min-width: 100px;

}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This ensures the container takes the full height of the viewport */
  }
  /* gpt.css */

.custom-results-box {
    max-width: 400px; /* Adjust the width as needed */
    border: 1px solid #ccc; /* Add border for better visibility */
    padding: 10px; /* Add padding for spacing inside the box */
    background-color: transparent; /* Set a background color if needed */
  }
  
  #generateBtn{
    margin-left: 120px;
  }

  @media screen and (max-width: 640px) {
    .mobile-input {
      /* Your mobile-specific input styles */
      font-size: 0.9rem;
      padding: 0.5rem;
    }
  }

 
  

  

 


